import { useState, useEffect, useContext } from "react";
import Login from "./pages/login.jsx";
import UsuarioContext from "./context/usuarios/UsuarioContext.js";
import AppRouter from "./routes/AppRouter.jsx";
import LoginRouter from "./routes/LoginRouter.jsx";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "bootstrap/dist/css/bootstrap.min.css";
export default function App() {
  const [username, setUsername] = useState("");
  const { getUsuario, usuario, setUsuario } = useContext(UsuarioContext);
  const[cuenta,setCuenta] = useState({
    usuario:"",
    password:""
  })
  
  const handleChange = (e) => {
    setCuenta({
      ...cuenta,
      [e.target.name]: e.target.value
    })
  }
  
  const handleSubmit = (e) => {
   
    // console.log(cuenta)
    getUsuario(cuenta)
  }
  
  // console.log(usuario)

  useEffect(() => {
   
const user =  JSON.parse(localStorage.getItem("log"))

    if (user) {
      if (user.length > 0) {
      setUsuario(user);
      
    } else {
      localStorage.setItem("log", JSON.stringify(usuario));
    }
  }
  }, []);

  useEffect(() => {
    // console.log(usuario)
    if (usuario.length > 0) {
      localStorage.setItem("log", JSON.stringify(usuario));
      setUsername(usuario[0].nombre);
    } else{
      localStorage.setItem("log", JSON.stringify(usuario));
      setUsername("")
    }
  }, [usuario]);

  if (username === "") {
    return (
      <div className=' d-flex justify-content-center align-content-center p-1 m-1'>
      <div className='row justify-content-center vh-100 align-content-center' id="loginform">
        <div className=' col-10'>
          <div className='card border rounded col-12 col-sm-12 col-md-12 col-lg-12 p-4 m-4'>
            <div className="card-header">
              <h2 id="headerTitle">Entrar</h2>
            </div>
            <div className="card-body">
              <div className="row mt-12">
                <label className='fs-4 fw-bold'>Usuario</label>
                <input className='form-control fs-4 fw-bold' type='text' placeholder='Usuario' id='usuario' name ='usuario'  autoComplete='off' value={cuenta.usuario} onChange={handleChange} />
              </div>
              <div className="row mt-12">
                <label className='fs-4 fw-bold'>Contraseña</label>
                <input className='form-control fs-4 fw-bold' type='password' placeholder='Contraseña' id='password' name ='password'   autoComplete='off' value={cuenta.password} onChange={handleChange} />
              </div>

              <div id="button" className="row mt-2">
                <button className='btn btn-primary fs-4 fw-bold'
                  onClick={handleSubmit}> Entrar</button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    );
  } else {
    return (
      <div>
        <AppRouter />
      </div>
    );
  }
}
