import {
  faAdd,
  faFilePen,
  faNoteSticky,
  faTrash,
  faDollar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import UsuarioContext from "../context/usuarios/UsuarioContext";
import "../App.css";
import Toast from "react-bootstrap/Toast";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import {
  Badge,
  Button,
  Col,
  DropdownButton,
  Form,
  Modal,
  ModalFooter,
  Row,
  Table,
} from "react-bootstrap";
import dayjs from "dayjs";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { get } from "jquery";
const Sesiones = ({ seleccionado }) => {
  const {
    getListaTerapias,
    ListaTerapias,
    delSesion,
    delsres,
    getSesiones,
    sesiones,
    AgregarSesion,
    resaddsesion,
    AbonarSesion,
    abonarresponse,setResabono,getPagos,pagos
  } = useContext(UsuarioContext);
  const [show, setshow] = useState(false);
  const [mtipo, setmtipo] = useState('success');
  const [derror, setderror] = useState("");
  const [ashow, setashow] = useState(false);
  const [eshow, seteshow] = useState(false);
  const [tbody, setTbody] = useState("");
  const now = dayjs().format("YYYY-MM-DD");
  const aparatos = makeAnimated();
  const [nterapia, setnterapia] = useState({
    paciente: "",
    terapia: "",
    sesiones: 0,
    sesiones_tomadas: 0,
    sesiones_semanales: 0,
    aparatos: "",
    costo: 0,
    fecha_inicio: "",
    fecha_fin: "",
    nota: "",
    metodo: 0,
    descuento: 0,
  });
  const [pterapia, setpterapia] = useState({
    terapia: "",
    costo: 0,
    pagado: 0,
    pagar: 0,
    metodo: 0,
    nota: "",
  });
  const CambioTerapiaId = (e) => {
    ListaTerapias.forEach((tera) => {
      if (tera.id === e.target.value) {
        setnterapia({
          ...nterapia,
          sesion:0,
          terapia: tera.id,
          sesiones: tera.sesiones,
          aparatos: tera.aparato,
          costo: tera.precio,
          metodo: tera.metodo,
          descuento: tera.descuento,
        });
      }
    });
  };

  const CambiopTerapia = (e) => {
    setpterapia({ ...pterapia, [e.target.name]: e.target.value });
  };

  const CambioTerapia = (e) => {
    setnterapia({ ...nterapia, [e.target.name]: e.target.value });
  };

  const BorrarSesion = (id) => {
    delSesion(id)
    // console.log(id);
  };
  // console.log(delsres)
  const pago = (id) => {
    getPagos(id)
    sesiones.forEach((tera) => {
      if (tera.id === id) {
        setpterapia({
          ...pterapia,
          sesion:tera.id,
          terapia: tera.id_terapia,
          costo: tera.costo,
          pagado: tera.pagado,
          pagar: 0,
          metodo: tera.metodo,
          descuento: tera.descuento,
        });
      }
    });
    setashow(!ashow);
    
  };
  const pagar = (id) => {
    if (pterapia.pagar > 0 && pterapia.metodo > 0) {
      AbonarSesion(pterapia);
      getSesiones(seleccionado, 0);

      setashow(!ashow);
    } else {
      setderror("Intoduce la información correcta");
      setmtipo('danger');
      seteshow(true);
    }
  };
  const numberFormat = (value) =>
    new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    }).format(value);

  const AgregarNuevaSesion = () => {
    AgregarSesion(nterapia);
  };
  React.useEffect(() => {
    let no_semanas = nterapia.sesiones / nterapia.sesiones_semanales - 1;

    let fecha_final = dayjs(nterapia.fecha_inicio)
      .add(no_semanas, "weeks")
      .format("YYYY-MM-DD");

    setnterapia({
      ...nterapia,
      fecha_fin: fecha_final,
    });
  }, [nterapia.fecha_inicio]);
  React.useEffect(() => {
    getListaTerapias();

  }, []);

  React.useEffect(() => {
    if(seleccionado !=0){
      getSesiones(seleccionado, 0);
      
    setnterapia({
      ...nterapia,
      paciente: seleccionado,
    });}
  }, [seleccionado]);

  React.useEffect(() => {
    if(seleccionado !=0){
    getSesiones(seleccionado, 0);
    }
    setshow(false);
  }, [resaddsesion]);

 

  const traepagos = (id) => {
    if (pagos.length > 0){
    return pagos.map((elemento) => {
       return (
        <tr key={elemento.id} className="fs-6 fw-bolder">
          <td> {numberFormat(elemento.cantidad)}</td>
          <td> {elemento.metodo}</td>
          <td> {elemento.fecha}</td>   
        </tr>
      );
    });}
  };


  React.useEffect(() => {
    if(abonarresponse.length > 0){
      setResabono()
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className=' bg-light'>
              <h1>Pago exitoso</h1>
              <p>El pago se registro con exito</p>
              <button
                onClick={() => {
                  onClose();
                }}
                className='btn btn-success btn-block'
              >
                Aceptar
              </button>
            </div>
          );
        }
      });

    getSesiones(nterapia.paciente, 0); }
  }, [abonarresponse]);

  // console.log(nterapia);
 
  return (
    <div className=" justify-content-between p-0 m-0 bg-primary-subtle">
      <Row className=" justify-content-between p-0 m-0 bg-primary-subtle">
        <Col md={4}>
          <div className="text-muted fs-4 text-center">Terapias</div>
        </Col>
        <Col md={4}></Col>
        <Col md={4}>
          {seleccionado > 0 ? (
            <div className="fw-bold fs-4 text-center float-end">
              <Button
                className="btn btn-primary fw-bold fs-5 text-center"
                onClick={() => setshow(true)}
              >
                <FontAwesomeIcon icon={faAdd} />
              </Button>
            </div>
          ) : (
            <div className="fw-bold fs-4 text-center float-end"></div>
          )}
        </Col>
      </Row>

      <Table className="table table-primary table-striped table-hover table-reponsive">
        <thead>
          <tr>
            <th className="fs-6 fw-bold ">Terapia</th>
            <th className="fs-6 fw-bold ">Sesiones</th>
            <th className="fs-6 fw-bold ">Inicia</th>
            <th className="fs-6 fw-bold ">Finaliza</th>
            <th className="fs-6 fw-bold ">Costo</th>
            <th className="fs-6 fw-bold ">Descuento</th>
            <th className="fs-6 fw-bold ">Pagado</th>
            <th className="fs-6 fw-bold ">Resta</th>
            <th className="fs-6 fw-bold ">Acción</th>
          </tr>
        </thead>
        <tbody>{
        sesiones.map((elemento) => {
      let resta = parseFloat(elemento.costo) - parseFloat(elemento.pagado) - parseFloat(elemento.descuento);
      return (
        <tr key={elemento.id} className="fs-6 fw-bolder">
          <td> {elemento.terapia}</td>
          <td> {elemento.sesiones}</td>
          <td> {elemento.fecha_inicio}</td>
          <td> {elemento.fecha_fin}</td>
          <td> {numberFormat(elemento.costo)}</td>
          <td> {numberFormat(elemento.descuento)}</td>
          <td> {numberFormat(elemento.pagado)}</td>
          <td> {numberFormat(resta)}</td>
          <td className="d-flex justify-content-center">
            <Button
              onClick={() => {
                BorrarSesion(elemento.id);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
            <Button
              onClick={() => {
                pago(elemento.id);
              }}
            >
              <FontAwesomeIcon icon={faDollar} />
            </Button>
          </td>
        </tr>
      );
    })   }</tbody>
      </Table>

      <Modal size="md" show={ashow} onHide={() => setashow(false)} centered>
        <Modal.Header
          className="bg-primary fw-bolder fs-6 text-white p-1 m-0"
          closeButton
        >
          <Modal.Title>Abonar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={4}>
              <Form.Label className="fs-6 fw-bold">Costo</Form.Label>
              <Form.Control
                value={pterapia.costo}
                size="md"
                type="number"
                name="costo"
                min={0}
                readOnly
              />
            </Col>
            <Col md={4}>
              <Form.Label className="fs-6 fw-bold">Pagado</Form.Label>
              <Form.Control
                value={pterapia.pagado}
                size="md"
                type="number"
                name="pagado"
                min={0}
                readOnly
              />
            </Col>
            <Col md={4}>
              <Form.Label className="fs-6 fw-bold">Abonar</Form.Label>
              <Form.Control
                value={pterapia.pagar}
                size="md"
                type="number"
                name="pagar"
                min={0}
                onChange={CambiopTerapia}
              />
            </Col>
            <Col md={6}>
              <Form.Label className="fs-6 fw-bold">Método de pago</Form.Label>
              <Form.Select
                size="md"
                name="metodo"
                onChange={CambiopTerapia}
                value={nterapia.metodo}
              >
                <option key={8} value={0}>
                  Selecciona Método
                </option>
                <option key={9} value={1}>
                  Efectivo
                </option>
                <option key={10} value={2}>
                  Débito
                </option>
                <option key={11} value={3}>
                  Credito
                </option>
              </Form.Select>
            </Col>
            <Col md={6}>
              <Form.Label className="fs-6 fw-bold">Aplicar</Form.Label>
              <Button className="btn-block" onClick={pagar}>
                Abonar
              </Button>
            </Col>
            <Col md={12} className="d-flex justify-content-center p-2">
              {" "}
              <Toast
                onClose={() => seteshow(false)}
                show={eshow}
                delay={3000}
                autohide
                bg={mtipo}
                width="100%"
              >
                <Toast.Header>
                  <strong className="me-auto">Error</strong>
                </Toast.Header>
                <Toast.Body className="fs-6 fw-bolder text-white">{derror}</Toast.Body>
              </Toast>
            </Col>
          </Row>
        </Modal.Body>
        <ModalFooter>
        <Table className="table table-primary table-striped table-hover table-reponsive">
        <thead>
          <tr>
            <th className="fs-6 fw-bold ">Monto</th>
            <th className="fs-6 fw-bold ">Método</th>
            <th className="fs-6 fw-bold ">Fecha</th>
          </tr>
        </thead>
        <tbody>{pagos.map((elemento) => {
       return (
        <tr key={elemento.id} className="fs-6 fw-bolder">
          <td> {numberFormat(elemento.cantidad)}</td>
          <td> {elemento.metodo}</td>
          <td> {elemento.fecha}</td>   
        </tr>
      );
    })}</tbody>
      </Table>
        </ModalFooter>
      </Modal>

      <Modal size="lg" show={show} onHide={() => setshow(false)} centered>
        <Modal.Header
          className="bg-primary fw-bolder fs-6 text-white"
          closeButton
        >
          <Modal.Title>Agregar Terapia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={5}>
              <Form.Label className="fs-6 fw-bold">Terapia</Form.Label>
              <Form.Select
                size="md"
                name="terapia"
                onChange={CambioTerapiaId}
                value={nterapia.terapia}
                className = 'fs-6 fw-bold'
              >
                <option key={0} value={0}>
                  Selecciona Terapia
                </option>
                {ListaTerapias.map((elemento) => {
                  return (
                    <option key={elemento.id} value={elemento.id}>
                      {elemento.nombre}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
            <Col md={2}>
              <Form.Label className="fs-6 fw-bold">No Sesiones</Form.Label>
              <Form.Control
                size="md"
                type="number"
                name="sesiones"
                min={1}
                max={30}
                onChange={CambioTerapia}
                value={nterapia.sesiones}
                className = 'fs-6 fw-bold'
                readOnly
              />
            </Col>
            <Col md={5}>
              <Form.Label className="fs-6 fw-bold">Aparatos</Form.Label>
              <Form.Control
                size="md"
                type="text"
                name="aparatos"
                onChange={CambioTerapia}
                value={nterapia.aparatos}
                className = 'fs-6 fw-bold'
                
              />
            </Col>
            <Col md={3}>
              <Form.Label className="fs-6 fw-bold">Descuento</Form.Label>
              <Form.Select
                size="md"
                name="descuento"
                onChange={CambioTerapia}
                value={nterapia.descuento}
                className = 'fs-6 fw-bold'
              >
                <option key={0} value={0}>
                  0%
                </option>
                <option key={1} value={5}>
                  5%
                </option>
                <option key={2} value={10}>
                  10%
                </option>
                <option key={3} value={15}>
                  15%
                </option>
                <option key={4} value={20}>
                  20%
                </option>
                <option key={5} value={30}>
                  30%
                </option>
                <option key={6} value={50}>
                  50%
                </option>
                <option key={7} value={100}>
                  100%
                </option>
              </Form.Select>
            </Col>
            <Col md={3}>
              <Form.Label className="fs-6 fw-bold">Costo</Form.Label>
              <Form.Control
                value={numberFormat(nterapia.costo)}
                size="md"
                type="text"
                name="costo"
                min={0}
                onChange={CambioTerapia}
                className = 'fs-6 fw-bold'
                
              />
            </Col>
            <Col md={3}>
              <Form.Label className="fs-6 fw-bold">Tomadas</Form.Label>
              <Form.Control
                size="md"
                type="number"
                name="sesiones_tomadas"
                value={nterapia.sesiones_tomadas}
                min={0}
                max={30}
                className = 'fs-6 fw-bold'
                onChange={CambioTerapia}
              />
            </Col>
            <Col md={3}>
              <Form.Label className="fs-6 fw-bold">Semanales</Form.Label>
              <Form.Control
                size="md"
                type="number"
                name="sesiones_semanales"
                value={nterapia.sesiones_semanales}
                min={0}
                max={30}
                onChange={CambioTerapia}
                className = 'fs-6 fw-bold'
              />
            </Col>
            <Col md={4}>
              <Form.Label className="fs-6 fw-bold">Fecha Inicial</Form.Label>
              <Form.Control
                size="md"
                type="date"
                name="fecha_inicio"
                min={now}
                onChange={CambioTerapia}
                value={nterapia.fecha_inicio}
                className = 'fs-6 fw-bold'
              />
            </Col>
            <Col md={4}>
              <Form.Label className="fs-6 fw-bold">Fecha Final</Form.Label>
              <Form.Control
                size="md"
                type="date"
                name="fecha_fin"
                min={now}
                onChange={CambioTerapia}
                value={nterapia.fecha_fin}
                className = 'fs-6 fw-bold'
              />
            </Col>

            <Col md={12}>
              <Form.Label className="fs-6 fw-bold">Nota</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={nterapia.nota}
                size="md"
                type="text"
                name="nota"
                onChange={CambioTerapia}
                className = 'fs-6 fw-bold'
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setshow(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={AgregarNuevaSesion}>
            <FontAwesomeIcon icon={faFilePen} /> Agregar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Sesiones;
