import React, { useContext, useEffect, useState } from "react";
import UsuarioContext from "../context/usuarios/UsuarioContext";
import Select from "react-select";
import Diagnosticos from "../componentes/diagnosticos";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import {
  faPencil,
  faUserAltSlash,
  faSave,
  faCancel,
  faUserAlt,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Perfil from "../componentes/perfil";
import Sesiones from "../componentes/sesiones";
import { FaUserPlus } from "react-icons/fa6";

const Pacientes = () => {
  const {
    usuario,
    getPacientes,
    getPaciente,
    setPaciente,
    listaPacientes,
    editPaciente,
    pacientes,
    paciente,
    EditUsuario,
    resedit,
    setDS,
    setPS,
    getSesiones,
    sesiones,
    lpres,
    spres,
    epres,
  } = useContext(UsuarioContext);

  const [edatos, setedatos] = useState(true);
  const [bdatos, setbdatos] = useState(true);
  const [gdatos, setgdatos] = useState(true);
  const [cdatos, setcdatos] = useState(true);
  const [adatos, setadatos] = useState(false);
  const [lpacientes, setlpacientes] = useState([]);
  const [editardatos, seteditardatos] = useState(true);

  const [seleccionado, setSeleccionado] = useState(0);

  const [Elpaciente, setElpaciente] = useState({
    id: 0,
    nombre: "",
    genero: "",
    preferencia: "",
    estatus: "",
  });

  const resetPaciente = () => {
    setElpaciente({
      id: 0,
      nombre: "",
      genero: "",
      preferencia: "",
      estatus: "",
    });
    getSesiones(0, 0);
  };

  const EditDatos = () => {
    seteditardatos(false);
    setedatos(true);
    setbdatos(true);
    setgdatos(false);
    setcdatos(false);
  };
  const CancelarDatos = () => {
    seteditardatos(true);
    setedatos(false);
    setbdatos(false);
    setgdatos(true);
    setcdatos(true);
  };
  const BorrarDatos = () => {
    seteditardatos(true);
    setedatos(true);
    setbdatos(true);
    setgdatos(true);
    setcdatos(true);
    EditUsuario({ ...Elpaciente, estatus: "0" });
  };
  const GuardarDatos = () => {
    seteditardatos(true);
    setedatos(false);
    setbdatos(false);
    setgdatos(true);
    setcdatos(true);
    seteditardatos(true);
    editPaciente(Elpaciente);
  };

  const pacienteCambia = (e) => {
    console.log(e.target)
    setElpaciente({ ...Elpaciente, [e.target.name]: e.target.value });
  };

  const AgregarDatos = () => {
    setPaciente(Elpaciente);
  };

  const pacienteSeleccionado = (e) => {
    const id = e.value;
    resetPaciente();
    setSeleccionado(id);
    getPaciente(id);
  };

  useEffect(() => {
    let lista = [];
    if (lpres.length > 0) {
      lpres.forEach((element) => {
        lista.push({
          value: element.id,
          label: element.nombre,
          name: "paciente",
        });
      });
      setlpacientes(lista);
    }
  }, [lpres]);

  console.log(Elpaciente);

  useEffect(() => {
    if (paciente.length > 0) {
      setadatos(false);
      setbdatos(false);
      setcdatos(true);
      setedatos(false);
      setgdatos(true);
      seteditardatos(true);
      setElpaciente(paciente[0]);
    } else {
      setbdatos(true);
      setcdatos(true);
      setedatos(true);
      setgdatos(true);
      seteditardatos(true);
      resetPaciente();
    }
  }, [paciente]);

  useEffect(() => {
    getPacientes();
    setSeleccionado(0);
    resetPaciente();
  }, [resedit]);

  useEffect(() => {
    listaPacientes(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setadatos(false);
    resetPaciente();
    listaPacientes();
  }, [spres]);
  // console.log(spres)
  return (
    <div>
      <div className="d-flex justify-content-center p-0 m-0 pt-4 mt-5">
        <div
          className="card bg-primary-subtle border border-2 border-primary rounded-2 p-2 m-2  "
          style={{ width: "90%" }}
        >
          <div className="card-header bg-primary text-white">
            <div className="card-title text-center fs-4 fw-bolder bg-primary W-75">
              Datos del Paciente
            </div>
          </div>
          <Row className=" justify-content-between p-0 m-0 bg-primary-subtle">
            <Col md={12} className=" bg-light  p-2 m-0   bg-primary-subtle">
              <Row className="d-flex">
                <Col md={4}>
                  <Select
                    options={lpacientes}
                    className="form-control fs-6 fw-bold p-1 m-1"
                    onChange={pacienteSeleccionado}
                    placeholder="Selecciona Paciente"
                  />
                </Col>
                <Col md={4}></Col>
                <Col md={4}>
                  <Button
                    className="btn-block"
                    onClick={() => {
                      setadatos(true);
                      resetPaciente();
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faUserPlus} /> Agregar Paciente
                  </Button>
                </Col>
              </Row>

              <Row className=" justify-content-between p-0 m-0 bg-primary-subtle">
                <Col md={4}>
                  <label htmlFor="nombre" className="fs-5 fw-bold">
                    {" "}
                    Nombre completo
                  </label>

                  <Form.Control
                    className=" form-control fs-6 fw-bold p-1 m-1"
                    name="nombre"
                    value={Elpaciente.nombre.toUpperCase()}
                    type="text"
                    minLength={20}
                    placeholder="Nombre completo"
                    onChange={pacienteCambia}
                    readOnly={editardatos}
                  />
                </Col>

                <Col md={3}>
                  <label htmlFor="genero" className="fs-5 fw-bold">
                    {" "}
                    Genero
                  </label>
                  <Form.Select
                    className=" form-control fs-6 fw-bold p-1 m-1"
                    value={Elpaciente.genero}
                    name="genero"
                    onChange={pacienteCambia}
                    disabled={editardatos}
                  >
                    <option value="0">Selecciona Genero</option>
                    <option value="M">Masculino</option>
                    <option value="F">Femenino</option>
                  </Form.Select>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="rol">
                    <label htmlFor="preferencia" className="fs-5 fw-bold">
                      {" "}
                      Preferencia
                    </label>
                    <Form.Select
                      className=" form-control fs-6 fw-bold p-1 m-1"
                      value={Elpaciente.preferencia}
                      name="preferencia"
                      id="preferencia"
                      onChange={pacienteCambia}
                      disabled={editardatos}
                    >
                      <option value="0">Selecciona Tipo</option>
                      <option value="F">Mujeres</option>
                      <option value="M">Hombres</option>
                      <option value="A">Ambos</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Row className="d-flex justify-content-center ">
                    <label className="fs-5 fw-bold"> Acciones</label>
                    <Col className="col-6">
                      <Button
                        className="btn-block"
                        hidden={edatos}
                        onClick={EditDatos}
                      >
                        {" "}
                        <FontAwesomeIcon icon={faPencil} />
                      </Button>
                    </Col>
                    <Col className="col-6">
                      <Button
                        className="btn-block"
                        hidden={bdatos}
                        onClick={BorrarDatos}
                      >
                        {" "}
                        <FontAwesomeIcon icon={faUserAltSlash} />
                      </Button>
                    </Col>
                    <Col className="col-6">
                      <Button
                        className="btn-block"
                        hidden={gdatos}
                        onClick={GuardarDatos}
                      >
                        {" "}
                        <FontAwesomeIcon icon={faSave} />
                      </Button>
                    </Col>
                    <Col className="col-6">
                      <Button
                        className="btn-block"
                        hidden={cdatos}
                        onClick={CancelarDatos}
                      >
                        {" "}
                        <FontAwesomeIcon icon={faCancel} />
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            {/* <Col
            md={12}
            className=" bg-light  p-2 m-0  "
          >
        <Perfil seleccionado={seleccionado} />
          </Col> */}
            {/* <Col
            md={12}
            className=" bg-light  p-2 m-0  "
          >
            <Diagnosticos seleccionado={seleccionado} />
          </Col> */}
            <Col>
              <Row className=" justify-content-between p-0 m-0 bg-primary-subtle">
                <Col md={12} className=" bg-light  p-2 m-0   bg-primary-subtle">
                  <Sesiones className="bg-ligth" seleccionado={seleccionado} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Modal show={adatos} size="md" centered>
            <Modal.Header className="p-2 m-0 bg-primary">
              <Modal.Title id="contained-modal-title-vcenter">
                Agregar Paciente
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="row">
              <div className="col-md-12 ">
                <label className="fs-6 fw-bold p-1">Nombre Completo</label>
                <input
                  type="text"
                  className="form-control fs-6 fw-semibold"
                  name="nombre"
                  id="nombre"
                  value={Elpaciente.nombre.toUpperCase()}
                  onChange={pacienteCambia}
                  required
                />
              </div>
              <div className="col-md-12 ">
                <label className="fs-6 fw-bold p-1">Genero</label>
                <Form.Select
                  className=" form-control fs-6 fw-bold p-1 m-1"
                  name="genero"
                  onChange={pacienteCambia}
                >
                  <option value="0">Selecciona Genero</option>
                  <option value="M">Masculino</option>
                  <option value="F">Femenino</option>
                </Form.Select>
              </div>
              <div className="col-md-12 ">
                <label className="fs-6 fw-bold p-1">Preferencia</label>
                <Form.Select
                  className=" form-control fs-6 fw-bold p-1 m-1"
                  name="preferencia"
                  onChange={pacienteCambia}
                >
                  <option value="0">Selecciona Tipo</option>
                  <option value="F">Mujeres</option>
                  <option value="M">Hombres</option>
                  <option value="A">Ambos</option>
                </Form.Select>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn btn-primary btn-sm" onClick={AgregarDatos}>
                Agregar
              </Button>
              <Button
                className="btn btn-danger btn-sm "
                onClick={() => {
                  setadatos(false);
                }}
              >
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Pacientes;
