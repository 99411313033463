import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import Select from "react-select";
import UsuarioContext from "../context/usuarios/UsuarioContext";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  ListGroup,
  Modal,
  Row,
} from "react-bootstrap";
import dayjs from "dayjs";
import "dayjs/locale/es";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import $, { get } from "jquery";
dayjs.locale("es");

export default function Cita() {
  const {
    getDisponibles,
    disponibles,
    listaPacientes,
    lpres,
    pacientes,
    getSesiones,
    sesiones,
    setEvento,
    resp,
  } = useContext(UsuarioContext);

  const { fecha } = useParams();
  const repetir = makeAnimated();
  const [asignado, setasignado] = useState(false);
   const [lpacientes, setlpacientes] = useState([]);
  const [nEvento, setnEvento] = useState({
    paciente: 0,
    terapia: 0,
    repetir: [],
    dias: [],
    veces: 0,
    fecha: fecha.split("--")[0],
    inicio: fecha.split("--")[1],
    fin: fecha.split("--")[2],
    terapeuta: 0,
    nota: "",
  });

  const EventoCambia = (e) => {
    setnEvento({ ...nEvento, [e.target.name]: e.target.value });
  };
  const EventoCambiaP = (e) => {
    setnEvento({ ...nEvento, [e.name]: e.value });
  };
  useEffect(() => {
    setnEvento({ ...nEvento, paciente: 0 });
    listaPacientes();
  }, []);

  useEffect(() => {
    setnEvento({ ...nEvento, terapia: 0 });
    getSesiones(nEvento.paciente, 0);
  }, [nEvento.paciente]);

  useEffect(() => {
    if (nEvento.paciente != 0) {
      getDisponibles(nEvento);
      setasignado(false);
      let dias = [];
      let lista = [];

      switch (dayjs(nEvento.fecha).format("dddd").slice(0, 3)) {
        case "lun":
          dias.push("Monday");
          lista.push({ value: "Monday", label: "Lun" });
          break;

        case "mar":
          dias.push("Tuesday");
          lista.push({ value: "Tuesday", label: "Mar" });
          break;

        case "mie":
          dias.push("Wednesday");
          lista.push({ value: "Wednesday", label: "Mie" });
          break;

        case "jue":
          dias.push("Thursday");
          lista.push({ value: "Thursday", label: "Jue" });
          break;

        case "vie":
          dias.push("Friday");
          lista.push({ value: "Friday", label: "Vie" });
          break;

        case "sab":
          dias.push("Saturday");
          lista.push({ value: "Saturday", label: "Sab" });
          break;

        default:
          break;
      }

      setnEvento({ ...nEvento, repetir: lista, dias: dias });
    }
  }, [nEvento.paciente, nEvento.inicio, nEvento.fin]);
  // console.log(nEvento);
  const EventoCambiaRepetir = (lista) => {
    let dias = [];
    lista.forEach((element) => {
      dias.push(element.value);
    });
    setnEvento({ ...nEvento, repetir: lista, dias: dias });
  };
  const GuardaEvento = () => {
    if (nEvento.paciente === 0) {
      $("#paciente").addClass("is-invalid");
      return;
    } else {
      $("#paciente").removeClass("is-invalid");
      $("#paciente").addClass("is-valid");
    }
    if (nEvento.diagnostico === 0) {
      $("#diagnostico").addClass("is-invalid");
      return;
    } else {
      $("#diagnostico").removeClass("is-invalid");
      $("#diagnostico").addClass("is-valid");
    }
    if (nEvento.terapia === 0) {
      $("#terapia").addClass("is-invalid");
      return;
    } else {
      $("#terapia").removeClass("is-invalid");
      $("#terapia").addClass("is-valid");
    }
    if (nEvento.fecha === "") {
      $("#fecha").addClass("is-invalid");
      return;
    } else {
      $("#fecha").removeClass("is-invalid");
      $("#fecha").addClass("is-valid");
    }
    if (nEvento.inicio === "") {
      $("#inicio").addClass("is-invalid");
      return;
    } else {
      $("#inicio").removeClass("is-invalid");
      $("#inicio").addClass("is-valid");
    }
    if (nEvento.fin === "") {
      $("#fin").addClass("is-invalid");
      return;
    } else {
      $("#fin").removeClass("is-invalid");
      $("#fin").addClass("is-valid");
    }
    if (nEvento.repetir.length < 1) {
      $("#repetir").addClass("is-invalid");
      return;
    } else {
      $("#repetir").removeClass("is-invalid");
      $("#repetir").addClass("is-valid");
    }
    if (nEvento.veces === 0) {
      $("#veces").addClass("is-invalid");
      return;
    } else {
      $("#veces").removeClass("is-invalid");
      $("#veces").addClass("is-valid");
    }
    if (nEvento.terapeuta === 0) {
      $("#terapeuta").addClass("is-invalid");
      return;
    } else {
      $("#terapeuta").removeClass("is-invalid");
      $("#terapeuta").addClass("is-valid");
    }

    setEvento(nEvento);
  };
  useEffect(() => {
    if (resp) {
      window.location.href = `/`;
    }
  }, [resp]);

  useEffect(() => {
    disponibles.forEach((element) => {
      if (asignado == false) {
        nEvento.terapeuta = element.id;
        setasignado(true);
      }
    });
  }, [asignado]);
  useEffect(() => {
    let lista = [];
    if (lpres.length > 0) {
      lpres.forEach((element) => {
        lista.push({
          value: element.id,
          label: element.nombre,
          name: "paciente",
        });
      });
      setlpacientes(lista);
    }
  }, [lpres]);
  return (
    <div className="container">
      <div className="d-flex justify-content-center p-0 m-0 pt-4 mt-5">
        <div className="card bg-primary-subtle border border-2 border-primary rounded-2 p-2 m-2 ">
          <div className="row">
            <div className="col-md-3 ">
              <label className="fs-6 fw-bold p-1">Paciente</label>
              <Select
              name="paciente"
                id="paciente"
                    options={lpacientes}
                    className="form-control fs-6 fw-bold p-0 m-0"
                    onChange={EventoCambiaP}
                    placeholder="Selecciona Paciente"
                    value={nEvento.paciente}
                  />
             
            </div>

            <div className="col-md-3 ">
              <label className="fs-6 fw-bold p-1">Terapía</label>
              <select
                name="terapia"
                id="terapia"
                className="form-control  fs-6 fw-semibold"
                onChange={EventoCambia}
                required
              >
                <option value="0">Selecciona terapia</option>
                {sesiones.map((elemento) => {
                  return (
                    <option key={elemento.id} value={elemento.id}>
                      {elemento.terapia}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="  col-md-2 ">
              <label className="fs-6 fw-bold p-1">Fecha de Cita</label>
              <input
                type="date"
                className="form-control fs-6 fw-semibold"
                name="fecha"
                id="fecha"
                value={nEvento.fecha}
                onChange={EventoCambia}
                required
              />
            </div>
            <div className="  col-md-2 col-6">
              <label className="fs-6 fw-bold p-1">Hora Inicia</label>
              <input
                type="time"
                className="form-control fs-6 fw-semibold"
                name="inicio"
                id="inicio"
                step="3600"
                value={nEvento.inicio}
                onChange={EventoCambia}
                required
              />
            </div>
            <div className=" col-md-2 col-6">
              <label className="fs-6 fw-bold p-1">Hora Termina</label>
              <input
                type="time"
                step="60"
                className="form-control fs-6 fw-semibold"
                name="fin"
                id="fin"
                value={nEvento.fin}
                onChange={EventoCambia}
                required
              />
            </div>

            <div className=" col-md-5 ">
              <label className="fs-6 fw-bold">Terapeuta</label>
              <select
                name="terapeuta"
                id="terapeuta"
                className="form-control  fs-6 fw-semibold"
                onChange={EventoCambia}
                required
              >
                {disponibles.map((elemento) => {
                  return (
                    <option key={elemento.id} value={elemento.id}>
                      {elemento.nombre}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="col-md-5 col-9">
              <label className="fs-6 fw-bold">Repetir cada</label>
              <Select
                closeMenuOnSelect={false}
                components={repetir}
                className="fs-6 fw-semibold"
                isMulti
                name="repetir"
                id="repetir"
                options={[
                  { value: "Monday", label: "Lun" },
                  { value: "Tuesday", label: "Mar" },
                  { value: "Wednesday", label: "Mié" },
                  { value: "Thursday", label: "Jue" },
                  { value: "Friday", label: "Vie" },
                  { value: "Saturday", label: "Sáb" },
                ]}
                value={nEvento.repetir}
                onChange={EventoCambiaRepetir}
                required
              />
            </div>
            <div className="col-md-2">
              <label className="fs-6 fw-bold">Citas</label>
              <input
                type="number"
                step="1"
                className="form-control fs-6 fw-semibold"
                name="veces"
                id="veces"
                value={nEvento.veces}
                onChange={EventoCambia}
                required
              />
            </div>

            <div className=" col-md-12">
              <label className="fs-6 fw-bold p-1">Nota</label>
              <input
                type="text"
                className="form-control fs-6 fw-semibold"
                name="nota"
                id="nota"
                value={nEvento.nota}
                onChange={EventoCambia}
                required
              />
            </div>
            <Col md={12} className="p-1">
              <Row className="p-0 m-0 d-flex justify-content-end">
                <Col md={3} className="p-1 m-0">
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm w-100"
                    onClick={GuardaEvento}
                  >
                    <FontAwesomeIcon icon={faCalendarCheck} /> Agendar{" "}
                  </button>
                </Col>
                <Col md={3} className="p-1 m-0">
                  <button
                    className="btn btn-danger btn-sm   w-100"
                    onClick={() => {
                      window.location.href = `/`;
                    }}
                  >
                    <FontAwesomeIcon icon={faRightFromBracket} /> Cerrar
                  </button>
                </Col>
              </Row>
            </Col>
          </div>
        </div>
      </div>
    </div>
  );
}
