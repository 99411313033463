import {
  faCancel,
  faPencil,
  faSave,
  faUserAltSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import UsuarioContext from "../context/usuarios/UsuarioContext";

export const Terapeutas = () => {
  const {
    getTerapeutas,
    terapeutas,
    terapeuta,
    getTerapeuta,
    usuario,
    EditUsuario,resedit
  } = useContext(UsuarioContext);
  const [edatos, setedatos] = useState(true);
  const [bdatos, setbdatos] = useState(true);
  const [gdatos, setgdatos] = useState(true);
  const [cdatos, setcdatos] = useState(true);
  const [editardatos, seteditardatos] = useState(true);
  const [Seleccionado, setSeleccionado] = useState(0);
  const [Elpaciente, setElpaciente] = useState({
    id: "",
    usuario: "",
    password: "",
    nombre: "",
    genero:"",
    rol: "",
    estatus: "",
    entrada: "",
    salida: "",
    lunes: "00:00",
    martes: "00:00",
    miercoles: "00:00",
    jueves: "00:00",
    viernes: "00:00",
    sabado: "00:00",
    slunes: false,
    smartes: false,
    smiercoles: false,
    sjueves: false,
    sviernes: false,
    ssabado: false,
  });
  const stringToBoolean = (stringValue) => {
    switch (stringValue) {
      case "1":
        return true;

      case "0":
        return false;

        default:
          return stringValue;
    }
  };

  const resetTerapeuta = () => {
    setElpaciente({
      id: "",
      usuario: "",
      password: "",
      nombre: "",
      genero:"",
      rol: "",
      estatus: "",
      entrada: "",
      salida: "",
      lunes: "00:00",
      martes: "00:00",
      miercoles: "00:00",
      jueves: "00:00",
      viernes: "00:00",
      sabado: "00:00",
      slunes: false,
      smartes: false,
      smiercoles: false,
      sjueves: false,
      sviernes: false,
      ssabado: false,
    });
  };

  const EditDatos = () => {
    seteditardatos(false);
    setedatos(true);
    setbdatos(true);
    setgdatos(false);
    setcdatos(false);
  };
  const CancelarDatos = () => {
    seteditardatos(true);
    setedatos(false);
    setgdatos(true);
    setcdatos(true);
  };

  const GuardarDatos = () => {
    seteditardatos(true);
    setedatos(false);
    setgdatos(true);
    setcdatos(true);
    EditUsuario(Elpaciente);
  };

  const pacienteCambia = (e) => {
    if (e.target.type === "checkbox") {
      setElpaciente({ ...Elpaciente, [e.target.name]: e.target.checked });
    } else {
      setElpaciente({ ...Elpaciente, [e.target.name]: e.target.value });
    }
  };
  React.useEffect(() => {
    getTerapeutas(usuario[0].id, usuario[0].rol, 2);
  }, []);

  React.useEffect(() => {
    console.log(terapeuta);
    if (terapeuta.length > 0) {
      setedatos(true);
      CancelarDatos();
      setElpaciente(terapeuta[0]);
      setedatos(false);
    } else {
      CancelarDatos();
      setedatos(true);
    }
  }, [terapeuta]);

  React.useEffect(() => {
    console.log(Seleccionado);
    if (Seleccionado > 0) {
      getTerapeuta(usuario[0].id, usuario[0].rol, Seleccionado);
      seteditardatos(true);
    } else {
      resetTerapeuta();
      setedatos(true);
      setgdatos(true);
      setcdatos(true);
      seteditardatos(true);
    }
  }, [Seleccionado]);
 console.log(resedit)
  console.log(Elpaciente);
  return (
    <div>
      <div className="d-flex justify-content-center p-0 m-0 pt-4 mt-5">
      <div className="card bg-primary-subtle border border-2 border-primary rounded-2 p-2 m-2 " style={{ width: "90%" }} >
          <Row className=" justify-content-between bg-primary-subtle p-0 m-0">
            <Col
              md={12}
              className="p-0 m-0  bg-primary-subtle"
            >
              <Row className="d-flex">
                <Col md={12}>
                <div className="card-header bg-primary text-white">
                  <div className="card-title text-center fs-4 fw-bolder bg-primary">
                    Datos del Terapeuta
                  </div>
                  </div>
                </Col>
                <Col md={12} className="d-flex justify-content-center p-2">
                  <Form.Select
                    value={Seleccionado}
                    className=" form-control fs-6 fw-medium p-1 m-1"
                    onChange={(e) => {
                      setSeleccionado(e.target.value);
                    }}
                  >
                    <option value={0}>Selecciona Terapeuta</option>
                    {terapeutas.map((elemento) => {
                      return (
                        <option key={elemento.id} value={elemento.id}>
                          {elemento.nombre}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
                <Col md={3}>
                  <Row className=" justify-content-between  p-1 m-0">
                    <Col md={12} className="p-1 m-0">
                      <Button
                        className="btn-block w-50 btn-sm p-0"
                        hidden={edatos}
                        onClick={EditDatos}
                      >
                        {" "}
                        <FontAwesomeIcon icon={faPencil} /> Editar
                      </Button>

                      <Button
                        className="btn-block w-50 btn-sm p-0"
                        hidden={gdatos}
                        onClick={GuardarDatos}
                      >
                        {" "}
                        <FontAwesomeIcon icon={faSave} /> Guardar
                      </Button>

                      <Button
                        className="btn-block w-50 btn-sm p-0"
                        hidden={cdatos}
                        onClick={CancelarDatos}
                      >
                        {" "}
                        <FontAwesomeIcon icon={faCancel} /> Cancelar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col md={2}>
                  <FloatingLabel
                    className="fs-6 fw-bolder p-1 m-1"
                    label="Usuario"
                  >
                    <Form.Control
                      className=" form-control fs-6 fw-medium p-1 m-1"
                      name="usuario"
                      value={Elpaciente.usuario}
                      type="text"
                      placeholder="Usuario"
                      onChange={pacienteCambia}
                      readOnly={editardatos}
                    />
                  </FloatingLabel>
                </Col>
              
                <Col md={3}>
                  <FloatingLabel
                    className="fs-6 fw-bolder p-1 m-1"
                    label="Nombre completo"
                  >
                    <Form.Control
                      className=" form-control fs-6 fw-medium p-1 m-1"
                      name="nombre"
                      value={Elpaciente.nombre.toUpperCase()}
                      type="text"
                      minLength={20}
                      placeholder="Nombre completo"
                      onChange={pacienteCambia}
                      readOnly={editardatos}
                    />
                  </FloatingLabel>
                </Col>
               
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="rol">
                    <FloatingLabel
                      className="fs-6 fw-bolder p-1 m-1"
                      label="Tipo de usuario"
                    >
                      <Form.Select
                        className=" form-control fs-6 fw-medium p-1 m-1"
                        value={Elpaciente.rol}
                        name="rol"
                        onChange={pacienteCambia}
                        disabled={editardatos}
                      >
                        <option value="0">Selecciona Tipo</option>
                        <option value="1">Paciente</option>
                        <option value="2">Terapeuta</option>
                        <option value="3">Administrador</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <FloatingLabel
                    className="fs-6 fw-bolder p-1 m-1"
                    label="Hora de entrada"
                  >
                    <Form.Control
                        className=" form-control fs-6 fw-medium p-1 m-1"
                        value={Elpaciente.entrada}
                        name="entrada"
                        type='time'
                        onChange={pacienteCambia}
                        disabled={editardatos}
                      />
                       
                  </FloatingLabel>
                </Col>
                <Col md={2}>
                  <FloatingLabel
                    className="fs-6 fw-bolder p-1 m-1"
                    label="Hora de salida"
                  >
                      <Form.Control
                        className=" form-control fs-6 fw-medium p-1 m-1"
                        value={Elpaciente.salida}
                        name="salida"
                      type='time'
                        onChange={pacienteCambia}
                        disabled={editardatos}
                      />
                      
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
      
                <Col md={12}>
                  <label className="fs-5 fw-bold text-center">
                    Días que trabaja
                  </label>
                </Col>
                <Col md={2} sm={6}>
                  <Form.Check
                    type="switch"
                    id="slunes"
                    name="slunes"
                    label="Lunes"
                    className="fs-6 fw-bold text-left"
                    checked={stringToBoolean(Elpaciente.slunes)}
                    onChange={pacienteCambia}
                    disabled={editardatos}
                  />
                </Col>
                <Col md={2} sm={6}>
                  <Form.Check
                    type="switch"
                    id="smartes"
                    name="smartes"
                    label="Martes"
                    className="fs-6 fw-bold text-left"
                    checked={stringToBoolean(Elpaciente.smartes)}
                    onChange={pacienteCambia}
                    disabled={editardatos}
                  />
                </Col>
                <Col md={2} sm={6}>
                  <Form.Check
                    type="switch"
                    id="smiercoles"
                    name="smiercoles"
                    label="Miercoles"
                    className="fs-6 fw-bold text-left"
                    checked={stringToBoolean(Elpaciente.smiercoles)}
                    onChange={pacienteCambia}
                    disabled={editardatos}
                  />
              
                </Col>
                <Col md={2} sm={6}>
                  <Form.Check
                    type="switch"
                    id="sjueves"
                    name="sjueves"
                    label="Jueves"
                    className="fs-6 fw-bold text-left"
                    checked={stringToBoolean(Elpaciente.sjueves)}
                    onChange={pacienteCambia}
                    disabled={editardatos}
                  />
                </Col>
                <Col md={2} sm={6}>
                  <Form.Check
                    type="switch"
                    id="sviernes"
                    name="sviernes"
                    label="Viernes"
                    className="fs-6 fw-bold text-left"
                    checked={stringToBoolean(Elpaciente.sviernes)}
                    onChange={pacienteCambia}
                    disabled={editardatos}
                  />
                </Col>
                <Col md={2} sm={6}>
                  <Form.Check
                    type="switch"
                    id="ssabado"
                    name="ssabado"
                    label="Sabado"
                    className="fs-6 fw-bold text-left"
                    checked={stringToBoolean(Elpaciente.ssabado)}
                    onChange={pacienteCambia}
                    disabled={editardatos}
                  />

                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <label className="fs-5 fw-bold text-center">
                    Horario de comida
                  </label>
                </Col>
                <Col md={2} sm={6}>

                  <input
                    type="time"
                    name="lunes"
                    id="lunes"
                    value={Elpaciente.lunes}
                    onChange={pacienteCambia}
                    className="form-control fs-6 fw-bold"
                    readOnly={editardatos}
                  />
                </Col>
                <Col md={2} sm={6}>
  
                  <input
                    type="time"
                    name="martes"
                    id="martes"
                    value={Elpaciente.martes}
                    onChange={pacienteCambia}
                    className="form-control fs-6 fw-bold"
                    readOnly={editardatos}
                    
                  />
                </Col>
                <Col md={2} sm={6}>
   
                  <input
                    type="time"
                    name="miercoles"
                    id="miercoles"
                    value={Elpaciente.miercoles}
                    onChange={pacienteCambia}
                    className="form-control fs-6 fw-bold"
                    readOnly={editardatos}
                  />
                </Col>
                <Col md={2} sm={6}>
              
                  <input
                    type="time"
                    name="jueves"
                    id="jueves"
                    value={Elpaciente.jueves}
                    onChange={pacienteCambia}
                    className="form-control fs-6 fw-bold"
                    readOnly={editardatos}
                  />
                </Col>
                <Col md={2} sm={6}>
        
                  <input
                    type="time"
                    name="viernes"
                    id="viernes"
                    value={Elpaciente.viernes}
                    onChange={pacienteCambia}
                    className="form-control fs-6 fw-bold"
                    readOnly={editardatos}
                  />
                </Col>
                <Col md={2} sm={6}>
               
                  <input
                    type="time"
                    name="sabado"
                    id="sabado"
                    value={Elpaciente.sabado}
                    onChange={pacienteCambia}
                    className="form-control fs-6 fw-bold"
                    readOnly={editardatos}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
