import React, { useContext, useState } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import UsuarioContext from "../context/usuarios/UsuarioContext.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { faPencil, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

export default function Terapias() {
  const {
    usuario,
    ListaTerapias,
    getListaTerapias,
    setTerapia,
    ressetterapia,
    borrarTerapia,
    resbterapia
  } = useContext(UsuarioContext);

  React.useEffect(() => {
    getListaTerapias(usuario[0].id, usuario[0].rol, 1);
  }, [resbterapia]);

  const [show, setShow] = useState(false);
  const [terapia, SetTerapia] = useState({
    id: "",
    nombre: "",
    descripcion: "",
    sesiones: 0,
    tipo: "",
    aparato: "",
    precio: 0.0,
    color: "#ffffff",
  });

  const terapiaCambia = (e) => {
    SetTerapia({ ...terapia, [e.target.name]: e.target.value });
  };

  const resetTerapia = () => {
    SetTerapia({
      ...terapia,
      id: "",
      nombre: "",
      descripcion: "",
      sesiones: 0,
      tipo: "",
      aparato: "",
      precio: 0.0,
      color: "#ffffff",
    });
  };

  const guardaTerapia = (e) => {
    setTerapia(terapia);
    getListaTerapias(usuario[0].id, usuario[0].rol, 1);
    setShow(false);
  };
  const handleClose = () => {
    resetTerapia();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const editTerapia = (id) => {
    ListaTerapias.forEach((tera) => {
      if (tera.id === id) {
        SetTerapia({
          ...terapia,
          id: tera.id,
          nombre: tera.nombre,
          descripcion: tera.descripcion,
          sesiones: tera.sesiones,
          tipo: tera.tipo,
          aparato: tera.aparato,
          precio: tera.precio,
          color: tera.color,
        });
      }
    });
    setShow(true);
  };

  const botonExpediente = (ListaTerapias) => {
    return (
      <div className="flex align-items-center gap-2">
        <Button variant="primary" onClick={() => editTerapia(ListaTerapias.id)}>
          <FontAwesomeIcon icon={faPencil} />
        </Button>
        <Button variant="primary" onClick={() => borrarTerapia({"id":ListaTerapias.id})}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </div>
    );
  };

  const vColor = (ListaTerapias) => {
    return (
      <div className="flex align-items-center gap-2">
        <Form.Control
          type="color"
          value={ListaTerapias.color}
          title="Selecciona Color"
          name="color"
          readOnly
        />
      </div>
    );
  };

  return (
    <div className="d-flex justify-content-center p-0 m-0 pt-4 mt-5">
      <div
        className="card  bg-primary-subtle border border-2 border-primary rounded-2 p-2 m-2 "
        style={{ width: "90%" }}
      >
        <div className="d-flex justify-content-between p-1 m-0 ">
          <div className="fs-5 fw-bold">Terapias Disponibles</div>
          <Button variant="primary" onClick={handleShow}>
            + Nueva Terapia
          </Button>
        </div>

        <DataTable
          value={ListaTerapias}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            field="nombre"
            header="Nombre"
            style={{ width: "15%" }}
          ></Column>
          <Column
            field="descripcion"
            header="Descripción"
            style={{ width: "20%" }}
          ></Column>
          <Column
            field="sesiones"
            header="Sesiones"
            style={{ width: "5%" }}
          ></Column>
          <Column field="tipo" header="Tipo" style={{ width: "10%" }}></Column>
          <Column
            field="aparato"
            header="Aparato"
            style={{ width: "15%" }}
          ></Column>
          <Column
            field="precio"
            header="Precio"
            style={{ width: "15%" }}
          ></Column>
          <Column
            header="Color"
            body={vColor}
            style={{ width: "10%" }}
          ></Column>
          <Column
            header="Acciones"
            body={botonExpediente}
            style={{ width: "20%" }}
          ></Column>
        </DataTable>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header className="fs-5 fw-bold bg-primary p-2 m-0 text-white">
            <Modal.Title>Datos de la terapia</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3" controlId="nombre">
                    <Form.Label className="fs-6 fw-bold  ">Nombre</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Nombre de Servicio"
                      name="nombre"
                      onChange={terapiaCambia}
                      value={terapia.nombre}
                      autoFocus
                      className="fs-6 fw-bold"
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="mb-3" controlId="descripcion">
                    <Form.Label className="fs-6 fw-bold  ">
                      Descripción
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      name="descripcion"
                      onChange={terapiaCambia}
                      value={terapia.descripcion}
                      className="fs-6 fw-bold"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="tipo">
                    <Form.Label className="fs-6 fw-bold  ">Tipo</Form.Label>
                    <Form.Select
                      name="tipo"
                      onChange={terapiaCambia}
                      value={terapia.tipo}
                      className="fs-6 fw-bold"
                    >
                      <option>Selecciona el Tipo</option>
                      <option value="Consulta">Consulta</option>
                      <option value="Fisica">Terapia Fisica</option>
                      <option value="Robótica">Terapia Robótica</option>
                      <option value="I Care">Terapia I Care</option>
                      <option value="Deglusión">Terapia Deglusión</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="aparato">
                    <Form.Label className="fs-6 fw-bold  ">Aparato</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Nombre Aparato"
                      name="aparato"
                      value={terapia.aparato}
                      onChange={terapiaCambia}
                      className="fs-6 fw-bold"
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3 text-right" controlId="sesiones">
                    <Form.Label className="fs-6 fw-bold  ">
                      No Sesiones
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="0"
                      name="sesiones"
                      value={terapia.sesiones}
                      onChange={terapiaCambia}
                      className="fs-6 fw-bold"
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3 text-right" controlId="precio">
                    <Form.Label className="fs-6 fw-bold  ">Precio</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="0.00"
                      name="precio"
                      value={terapia.precio}
                      onChange={terapiaCambia}
                      className="fs-6 fw-bold"
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3 text-right" controlId="color">
                    <Form.Label className="fs-6 fw-bold  ">Color</Form.Label>
                    <Form.Control
                      type="color"
                      value={terapia.color}
                      title="Selecciona Color"
                      name="color"
                      onChange={terapiaCambia}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={guardaTerapia}>
              <FontAwesomeIcon icon={faSave} /> Guardar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
