import { useEffect, useContext, useState, useCallback } from "react";

import UsuarioContext from "../context/usuarios/UsuarioContext.js";

import { Calendar, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AddEvento from "../componentes/addevento.jsx";
import {   useNavigate } from "react-router-dom";
import "../App.css";


dayjs.locale("es");

const localizer = dayjsLocalizer(dayjs);

export default function Calendario() {
  const { eventos, usuario, getEventos } = useContext(UsuarioContext);
  const [start, setstart] = useState("");
  const [end, setend] = useState("");
  const [addeve, setaddeve] = useState(false);

  const navigate = useNavigate();

  const eventPropGetter = (event, start, end, isSelected) => ({
    ...(dayjs(start).hour() < 20 && {
      style: {
        backgroundColor: event.color,
        border: '1px solid #000',
        color: '#000',
        fontSize: '13px',
        fontWeight: 'bold',
      },

    
  
  }),
});
  
  useEffect(() => {
    if (usuario.length > 0) {
      getEventos(usuario[0].id, usuario[0].rol);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let agenda = [];
  if (eventos && eventos.length > 0) {
    eventos.forEach(function (element) {
      agenda.push({
        title:  element.paciente + "   -    " + element.terapia ,
        start: dayjs(element.start).toDate(),
        end: dayjs(element.end).toDate(),
        paciente: element.paciente,
        terapia: element.terapia,
        id:element.id,
        color:element.color
      });
    });
  }else{
    agenda.push({
      title: '',
      start: dayjs('01/01/2022').toDate(),
      end: dayjs('01/01/2022').toDate(),
      paciente: 1,
      terapia: 1,
      id:1,
      color:''
    });

  }

 

  const handleSelectEvent = (e) => {

    navigate(`/terapia/${e.id}`);
  
   };
  // console.log(addeve)

  const handleSelectSlot = (e) => {
      // console.log(e)
    
    navigate(`/cita/${dayjs(e.start).format('YYYY-MM-DD--HH:mm') + '--'+ dayjs(e.end).format('HH:mm')}`);

  };
  if (usuario.length > 0) {
    if (usuario[0].rol !== 3) {
      return (
        <div>
          <div className="d-flex justify-content-center p-0 m-0 pt-4 mt-5">
            <div className="card bg-primary-subtle border border-2 border-primary rounded-2 p-2 m-0 fs-5 ">
            <Calendar
              localizer={localizer}
              events={agenda}
              startAccessor="start"
              endAccessor="end"
              views={["day","week","month"]}
              min={dayjs().set("hour", 7).set("minute", 0).set("second", 0)}
              max={dayjs().set("hour", 19).set("minute", 0).set("second", 0)}
              defaultView="day"
              eventPropGetter={eventPropGetter}
              step={30}
              style={{ height: "85vh", width: "90vw" }}
              selectable
              onSelectEvent={handleSelectEvent}
              onSelectSlot={handleSelectSlot}
              // onSelecting={handleSelectEvent}
              messages={{
                next: "Siguiente",
                previous: "Anterior",
                today: "Hoy",
                month: "Mes",
                week: "Semana",
                day: "Día",
              }}
              popup
            />
          </div>
        </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="d-flex justify-content-center pt-5 mt-5">
          <div className="card bg-primary-subtle border border-2 border-primary rounded-2 p-2 m-0   fs-4 fw-bold">
            <Calendar
              localizer={localizer}
              events={agenda}
              startAccessor="start"
              endAccessor="end"
              views={["day", "week", "month"]}
              min={dayjs().set("hour", 7).set("minute", 0).set("second", 0)}
              max={dayjs().set("hour", 19).set("minute", 0).set("second", 0)}
              defaultView="day"
              step={30}
              eventPropGetter={eventPropGetter}
              style={{ height: "85vh", width: "90vw" }}
              selectable
              onSelectEvent={handleSelectEvent}
              onSelectSlot={handleSelectSlot}
              // onSelecting={handleSelectEvent}
              messages={{
                next: ">>",
                previous: "<<",
                today: "Hoy",
                month: "Mes",
                week: "Semana",
                day: "Día",
              }}
              popup
            />
          </div>
           <AddEvento start={start} end={end} setaddeve={setaddeve} />
     </div>
     </div>
      );
    }
  }
}
