import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import { faSave, faFileCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FaCircleExclamation } from "react-icons/fa6";
import UsuarioContext from "../context/usuarios/UsuarioContext";
import toast, { Toaster } from "react-hot-toast";
import DataTable from "react-data-table-component";
import {
  Form,
  Container,
  Row,
  Col,
  Button,
  FloatingLabel,
  Alert,
  Spinner,
  Modal,
} from "react-bootstrap";
const NuevoUsuario = () => {
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [Nuevo, setNuevo] = useState({
    usuario: "",
    password: "",
    nombre: "",
    correo: "",
    telefono: "",
    rol: "",
    genero: "",
  });

  const resetForm = () => {
    setNuevo({
      usuario: "",
      password: "",
      nombre: "",
      rol: "",
      genero: "",
    });
  };
  const paginationComponentOptions = {
    // rowsPerPageText: "Filas por página",
    // rangeSeparatorText: "de",
    selectAllRowsItem: false,
    selectAllRowsItemText: "Todos",
  };

  const notifyOK = (msg) =>
    toast(msg, {
      duration: 4000,
      position: "bottom-center",
      className: "bg-success p-2 m-2 fs-5 fw-bolder text-white",
    });
  const notifyError = (msg) =>
    toast(msg, {
      duration: 4000,
      position: "bottom-center",
      className: "bg-danger p-2 m-2 fs-5 fw-bolder text-white",
    });
  const { usuario, AddUsuario, resadd, setResadd, pacientes, getPacientes } =
    useContext(UsuarioContext);

  const nuevoCambia = (e) => {
    setNuevo({ ...Nuevo, [e.target.name]: e.target.value });
  };
  const [oculto, setOculto] = useState(false);
  const form = useRef(null);
  const GuardaNuevo = (e) => {
    const form = e.currentTarget;

    if (form.checkValidity() === true) {
      setResadd();
      setShow(false);
      setOculto(true);
      AddUsuario(Nuevo);
    } else {
      setShow(true);
    }
    e.preventDefault();
    setValidated(true);
    e.stopPropagation();
  };

  React.useEffect(() => {
    if (resadd.msg) {
      if (resadd.res) {
        notifyOK("Usuario Creado Id =" + resadd.msg);
        setResadd();
        resetForm();
      } else {
        notifyError("Error :" + resadd.msg);
        setResadd();
        resetForm();
      }
      setOculto(false);
      getPacientes(usuario[0].id, usuario[0].rol, 0);
    }
  }, [resadd]);

  React.useEffect(() => {
    getPacientes(usuario[0].id, usuario[0].rol, 0);
  }, []);

  const columns = [
    {
      name: "Nombre",
      selector: (row) => row.nombre,
      sortable: true,
    },
    {
      name: "Tipo",
      selector: (row) => row.tipo,
      sortable: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "40px",
        fontsize: "24px",
        fontFamily: "Roboto",
        fontWeight: "bold",
        fontStyle: "italic",
        paddingLeft: "2px", // override the row padding for all rows
        paddingRight: "2px",
        borderBottom: "1px solid #ddd",
      },
    },
    headCells: {
      style: {
        fontsize: "34px",
        fontFamily: "Roboto",
        fontWeight: "bold",
        fontStyle: "italic",
        paddingLeft: "2px", // override the row padding for all rows
        paddingRight: "2px",
        borderBottom: "1px solid #ddd",
      },
    },
    cells: {
      style: {
        fontsize: "34px",
        fontFamily: "Roboto",
        fontWeight: "bold",
        fontStyle: "italic",
        paddingLeft: "2px", // override the row padding for all rows
        paddingRight: "2px",
        borderBottom: "1px solid #ddd",
      },
    },
  };

  return (
    <div>
      <div className="d-flex justify-content-center p-0 m-0 " >
      <div className="card bg-primary-subtle border border-2 border-primary rounded-2 p-2 m-2 "  style={{ width: "90%" }}>
        <Row className=" d-flex justify-content-center  col-12 border p-0 m-0">
          <Col md={6} className=' rounded-2 p-1' >
            <Form
              ref={form}
              noValidate
              validated={validated}
              onSubmit={GuardaNuevo}
              autoComplete="false"
            >
              <Form.Group className="mb-3" controlId="usuariov">
                <FloatingLabel className="fs-5 fw-bolder" label="Usuario">
                  <Form.Control
                    name="usuario"
                    value={Nuevo.usuario}
                    type="text"
                    placeholder="Usuario"
                    onChange={nuevoCambia}
                    required
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="password">
                <FloatingLabel className="fs-5 fw-bolder" label="Password">
                  <Form.Control
                    name="password"
                    value={Nuevo.password}
                    type="password"
                    placeholder="Password"
                    onChange={nuevoCambia}
                    required
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="nombre">
                <FloatingLabel
                  className="fs-5 fw-bolder"
                  label="Nombre completo"
                >
                  <Form.Control
                    name="nombre"
                    value={Nuevo.nombre.toUpperCase()}
                    type="text"
                    minLength={20}
                    placeholder="Nombre completo"
                    onChange={nuevoCambia}
                    required
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="rol">
                <FloatingLabel className="fs-5 fw-bolder" label="Genero">
                  <Form.Select
                    value={Nuevo.genero}
                    className="fw-semibold"
                    name="genero"
                    onChange={nuevoCambia}
                  >
                    <option value="0">Selecciona Genero</option>
                    <option value="F">Femenino</option>
                    <option value="M">Masculino</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="rol">
                <FloatingLabel
                  className="fs-5 fw-bolder"
                  label="Tipo de usuario"
                >
                  <Form.Select
                    value={Nuevo.rol}
                    className="fw-semibold"
                    name="rol"
                    onChange={nuevoCambia}
                  >
                    <option value="0">Selecciona Tipo</option>
                    <option value="2">Terapeuta</option>
                    <option value="3">Administrador</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group
                className="mb-3 justify-content-end align-content-end"
                controlId="guardar"
              >
                <Button
                  type="submit"
                  className="btn btn-primary btn-block fw-bold fs-5"
                  disabled={oculto}
                >
                  {" "}
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    hidden={!oculto}
                  />{" "}
                  Guardar
                </Button>
              </Form.Group>
            </Form>
            <Alert
              show={show}
              variant="danger"
              onClose={() => setShow(false)}
              dismissible
            >
              <Alert.Heading className="fw-bold fs-4">Error!!</Alert.Heading>
              <p className="fw-bold fs-5">
                Verifica los campos marcados... <FaCircleExclamation />
              </p>
            </Alert>

            <Toaster />
          </Col>
          <Col md={6} className=' rounded-2 p-1'>
            <DataTable
              columns={columns}
              data={pacientes}
              pagination
              paginationComponentOptions={paginationComponentOptions}
              customStyles={customStyles}
            />
          </Col>
        </Row>
      </div>
      </div>
    </div>
  );
};

export default NuevoUsuario;
