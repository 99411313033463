import { useContext, useState, useEffect } from "react";
import dayjs from "dayjs";
import UsuarioContext from "../context/usuarios/UsuarioContext.js";
import {
  faRefresh,
  faCalendarCheck,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import $, { get } from "jquery";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Col, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
export default function AddEvento({ start, end, setaddeve }) {
  console.log(start, end, setaddeve)
  const {
    setEvento,
    getExpediente,
    expediente,
    usuario,
    getEventos,
    getPacientes,
    getDisponibles,
    disponibles,
    pacientes,
    getSesiones,
    sesiones,
    resp,
    getTerapeutas,
    terapeutas,
  } = useContext(UsuarioContext);
  
  const repetir = makeAnimated();
  const [nEvento, setnEvento] = useState({
    paciente: 0,
    terapia: 0,
    repetir: [],
    dias: [],
    veces: 0,
    diagnostico: 0,
    doctor: "",
    aparato: "",
    fecha: "",
    inicio: "",
    fin: "",
    terapeuta: 0,
    nota: "",
  });

  const resetEvento = () => {
    setEvento({
      paciente: 0,
      terapia: 0,
      repetir: [],
      dias: [],
      veces: 0,
      diagnostico: 0,
      doctor: "",
      aparato: "",
      fecha: "",
      inicio: "",
      fin: "",
      terapeuta: 0,
      nota: "",
    });
  };
  // console.log(nEvento);
  const EventoCambia = (e) => {
    setnEvento({ ...nEvento, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if(nEvento.paciente != 0){
    getDisponibles(nEvento);
    }
  }, [nEvento.paciente]);

  console.log(disponibles);
  const GuardaEvento = () => {
    if (nEvento.paciente === 0) {
      $("#paciente").addClass("is-invalid");
      return;
    } else {
      $("#paciente").removeClass("is-invalid");
      $("#paciente").addClass("is-valid");
    }
    if (nEvento.diagnostico === 0) {
      $("#diagnostico").addClass("is-invalid");
      return;
    } else {
      $("#diagnostico").removeClass("is-invalid");
      $("#diagnostico").addClass("is-valid");
    }
    if (nEvento.terapia === 0) {
      $("#terapia").addClass("is-invalid");
      return;
    } else {
      $("#terapia").removeClass("is-invalid");
      $("#terapia").addClass("is-valid");
    }
    if (nEvento.fecha === "") {
      $("#fecha").addClass("is-invalid");
      return;
    } else {
      $("#fecha").removeClass("is-invalid");
      $("#fecha").addClass("is-valid");
    }
    if (nEvento.inicio === "") {
      $("#inicio").addClass("is-invalid");
      return;
    } else {
      $("#inicio").removeClass("is-invalid");
      $("#inicio").addClass("is-valid");
    }
    if (nEvento.fin === "") {
      $("#fin").addClass("is-invalid");
      return;
    } else {
      $("#fin").removeClass("is-invalid");
      $("#fin").addClass("is-valid");
    }
    if (nEvento.repetir.length < 1) {
      $("#repetir").addClass("is-invalid");
      return;
    } else {
      $("#repetir").removeClass("is-invalid");
      $("#repetir").addClass("is-valid");
    }
    if (nEvento.veces === 0) {
      $("#veces").addClass("is-invalid");
      return;
    } else {
      $("#veces").removeClass("is-invalid");
      $("#veces").addClass("is-valid");
    }
    if (nEvento.terapeuta === 0) {
      $("#terapeuta").addClass("is-invalid");
      return;
    } else {
      $("#terapeuta").removeClass("is-invalid");
      $("#terapeuta").addClass("is-valid");
    }

    setEvento(nEvento);
  };

  const EventoCambiaRepetir = (lista) => {
    var dias = [];
    lista.forEach((element) => {
      dias.push(element.value);
    });
    setnEvento({ ...nEvento, repetir: lista, dias: dias });
  };

  useEffect(() => {
    setnEvento({ ...nEvento, paciente: 0 });
    getPacientes(usuario[0].id, usuario[0].rol, 1);
  }, []);

  useEffect(() => {
    getExpediente(nEvento.paciente);
    setnEvento({ ...nEvento, aparato: [], diagnostico: 0, terapia: 0 });
  }, [nEvento.paciente]);

  useEffect(() => {
    getSesiones(nEvento.paciente, nEvento.diagnostico);
  }, [nEvento.diagnostico]);

  useEffect(() => {
    // console.log(sesiones);
    sesiones.forEach((element) => {
      if (element.id == nEvento.terapia) {
        console.log(element.aparatos);
        setnEvento({ ...nEvento, aparato: JSON.parse(element.aparatos) });
      }
    });
  }, [nEvento.terapia]);

  useEffect(() => {
    getEventos(usuario[0].id, usuario[0].rol);
    setnEvento({ ...nEvento, paciente: 0 });
  }, [resp]);

  useEffect(() => {
    setnEvento({
      ...nEvento,
      fecha: dayjs(start).format("YYYY-MM-DD"),
      inicio: dayjs(start).format("HH:mm:ss"),
      fin: dayjs(end).format("HH:mm:ss"),
    });
  }, [start, end]);

useEffect(() => {
  getTerapeutas(usuario[0].id, usuario[0].rol, nEvento.inicio, nEvento.fin);
}, [nEvento]);



console.log(resp)
  return (
    <Modal show={true} size="xl" centered>
      <Modal.Header className="p-2 m-0 bg-warning">
        <Modal.Title id="contained-modal-title-vcenter">
          Agendar Cita
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="row">
        <div className="col-md-4  ">
          <label className="fs-6 fw-bold p-1">Paciente</label>
          <select
            type="number"
            name="paciente"
            id="paciente"
            className="form-control  fs-6 fw-semibold"
            value={nEvento.paciente}
            onChange={EventoCambia}
            required
          >
            {" "}
            <option key={0} value="0">Selecciona Paciente</option>
            {pacientes.map((elemento) => {
              return (
                <option key={pacientes.id} value={elemento.id}>
                  {elemento.nombre}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-4  ">
          <label className="fs-6 fw-bold p-1">Diagnostico</label>
          <select
            name="diagnostico"
            id="diagnostico"
            className="form-control fs-6 fw-semibold"
            onChange={EventoCambia}
            required
          >
            <option value="0">Selecciona terapia</option>
            {expediente.map((elemento) => {
              return (
                <option key={elemento.id} value={elemento.id}>
                  {elemento.diagnostico_corto}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-4  ">
          <label className="fs-6 fw-bold p-1">Terapía</label>
          <select
            name="terapia"
            id="terapia"
            className="form-control  fs-6 fw-semibold"
            onChange={EventoCambia}
            required
          >
            <option value="0">Selecciona terapia</option>
            {sesiones.map((elemento) => {
              return (
                <option key={elemento.id} value={elemento.id}>
                  {elemento.terapia}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-2 ">
          <label className="fs-6 fw-bold p-1">Aparato</label>
          <div className="form-control fs-6 fw-semibold h-50">
            {nEvento.aparato}
          </div>
        </div>

        <div className="  col-md-2 ">
          <label className="fs-6 fw-bold">Fecha de Cita</label>
          <input
            type="date"
            className="form-control fs-6 fw-semibold"
            name="fecha"
            id="fecha"
            value={nEvento.fecha}
            onChange={EventoCambia}
            required
          />
        </div>
        <div className="  col-md-2 col-6">
          <label className="fs-6 fw-bold">Hora Inicia</label>
          <input
            type="time"
            className="form-control fs-6 fw-semibold"
            name="inicio"
            id="inicio"
            value={nEvento.inicio}
            onChange={EventoCambia}
            required
          />
        </div>
        <div className=" col-md-2 col-6">
          <label className="fs-6 fw-bold">Hora Termina</label>
          <input
            type="time"
            step="60"
            className="form-control fs-6 fw-semibold"
            name="fin"
            id="fin"
            value={nEvento.fin}
            onChange={EventoCambia}
            required
          />
        </div>

        <div className=" col-md-4 ">
          <label className="fs-6 fw-bold p-1">Terapeuta</label>
          <select
            name="terapeuta"
            id="terapeuta"
            className="form-control  fs-6 fw-semibold"
            onChange={EventoCambia}
            required
          >
            <option value="0">Selecciona terapeuta</option>
            {disponibles.map((elemento) => {
              return (
                <option key={elemento.id} value={elemento.id}>
                  {elemento.nombre}
                </option>
              );
            })}
          </select>
        </div>

        <div className="col-md-6 col-9">
          <label className="fs-6 fw-bold">Repetir cada</label>
          <Select
            closeMenuOnSelect={false}
            components={repetir}
            className="fs-6 fw-semibold"
            isMulti
            name="repetir"
            id="repetir"
            options={[
              { value: "Monday", label: "Lun" },
              { value: "Tuesday", label: "Mar" },
              { value: "Wednesday", label: "Mié" },
              { value: "Thursday", label: "Jue" },
              { value: "Friday", label: "Vie" },
              { value: "Saturday", label: "Sáb" },
            ]}
            value={nEvento.repetir}
            onChange={EventoCambiaRepetir}
            required
          />
        </div>
        <div className="col-md-2 col-3">
          <label className="fs-6 fw-bold">Citas</label>
          <input
            type="number"
            step="1"
            className="form-control fs-6 fw-semibold"
            name="veces"
            id="veces"
            value={nEvento.veces}
            onChange={EventoCambia}
            required
          />
        </div>

    
        <div className=" col-md-9 ">
          <label className="fs-6 fw-bold p-1">Nota</label>
          <input
            type="text"
            className="form-control fs-6 fw-semibold"
            name="nota"
            id="nota"
            value={nEvento.nota}
            onChange={EventoCambia}
            required
          />
        </div>
        <Col md={3} className="">
          <Row className="p-0 m-0">
            <label className="fs-6 fw-bold p-1">Acciones</label>
            <Col md={6} className="p-1 m-0">
              <button
                type="submit"
                className="btn btn-primary btn-sm w-100"
                onClick={GuardaEvento}
              >
                <FontAwesomeIcon icon={faCalendarCheck} /> Agendar{" "}
              </button>
            </Col>
            <Col md={6} className="p-1 m-0">
              <button
                className="btn btn-danger btn-sm   w-100"
                onClick={() => {
                  setaddeve(false);
                }}
              >
                <FontAwesomeIcon icon={faRightFromBracket} /> Cerrar
              </button>
            </Col>
          </Row>
        </Col>
      </Modal.Body>
    </Modal>
  );
}
