import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Spinner } from "reactstrap";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import "dayjs/locale/es";
dayjs.locale("es");
const BotonExcelEstilizado = ({ reporte }) => {
  const [loading, setLoading] = useState(false);

  const titulo = [{ A: "Reporte de Terapeuta "  }, {}];

  const informacionAdicional = {
    A: "Creado el " + dayjs().format("DD/MM/YYYY"),
  };

  const longitudes = [5, 35, 25, 20, 10, 10, 10];

  const handleDownload = () => {
    setLoading(true);

    let tabla = [
      {
        A: "Terapeuta",
        B: "Paciente",
        C: "Terapia",
        D: "Fecha",
        E: "Inicio",
        F: "Fin",
        G: "Duración"
      },
    ];

    reporte.forEach((evento) => {
      tabla.push({
        A: evento.terapeuta,
        B: evento.paciente,
        C: evento.terapia,
        D: evento.fecha,
        E: dayjs(evento.inicio).format("HH:mm"),
        F: dayjs(evento.fin).format("HH:mm"),
        G: evento.duracion,
      });
    });

    const dataFinal = [...titulo, ...tabla, informacionAdicional];

    setTimeout(() => {
      creandoArchivo(dataFinal);
      setLoading(false);
    }, 1000);
  };

  const creandoArchivo = (dataFinal) => {
    const libro = XLSX.utils.book_new();

    const hoja = XLSX.utils.json_to_sheet(dataFinal, { skipHeader: true });

    hoja["!merges"] = [
      XLSX.utils.decode_range("A1:G1"),
      XLSX.utils.decode_range("A2:G2"),
      XLSX.utils.decode_range("A34:G34"),
    ];

    let propiedades = [];

    longitudes.forEach((col) => {
      propiedades.push({
        width: col,
      });
    });

    hoja["!cols"] = propiedades;

    XLSX.utils.book_append_sheet(libro, hoja, "Productos");

    XLSX.writeFile(libro, "ProductosEstilizado.xlsx");
  };

  return (
    <>
      {!loading ? (
        <Button color="success" onClick={handleDownload}>
          <FontAwesomeIcon icon={faFileExcel} /> Exportar 
        </Button>
      ) : (
        <Button color="success" disabled>
          <Spinner size="sm">Loading...</Spinner>
          <span> Generando...</span>
        </Button>
      )}
    </>
  );
};

export default BotonExcelEstilizado;
