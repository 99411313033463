import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Container, Table } from "reactstrap";
import BotonExcelDefault from "../componentes/BotonExcelDefault";
import BotonExcelEstilizado from "../componentes/BotonExcelEstilizado";
import Select from "react-select";
import UsuarioContext from "../context/usuarios/UsuarioContext";
import dayjs from "dayjs";
import "dayjs/locale/es";
dayjs.locale("es");
const Reportes = () => {
  const {
    getTerapeutas,
    terapeutas,
    getReporte,
    reporte,
    usuario
  } = useContext(UsuarioContext);
  const [lterapeutas, setlterapeutas] = useState([]);
  const [tera, settera] = useState({
    terapeuta: "",
    fecha_inicial: "00:00",
    fecha_final: "00:00",
  });
  const terapeutaCambia = (e) => {
    console.log(e)
    settera({ ...tera, [e.name]: e.value });
    
  };
  const teraCambia = (e) => {
    settera({ ...tera, [e.target.name]: e.target.value });
  };
  const generaReporte = async () => {
    await getReporte(tera);
  };

  useEffect(() => {
    getTerapeutas(usuario[0].id, usuario[0].rol, 2);
  }, []);

 useEffect(() => {
    let lista = [];
    if (terapeutas.length > 0) {
      terapeutas.forEach((element) => {
        lista.push({
          value: element.id,
          label: element.nombre,
          name: "terapeuta",
        });
      });
      setlterapeutas(lista);
    }},[terapeutas])

console.log(tera)
  return (
    <div className="d-flex justify-content-center p-0 m-0 pt-4 mt-5">
    <Container className="pt-5">
      <Card>
        <CardHeader>
          <div className="d-flex justify-content-between row">
           
            <div className="col-md-3 ">
              <label className="fs-6 fw-bold p-1">Terapeuta</label>
              <Select
              name="terapeuta"
                id="terapeuta"
                    options={lterapeutas}
                    className="form-control fs-6 fw-bold p-0 m-0"
                    onChange={terapeutaCambia}
                    placeholder="Selecciona Terapeuta"
                    
                  />
             
            </div>
            <div className="col-md-3 ">
              <label className="fs-6 fw-bold p-1">Fecha inicial</label>
              <input
                type="date"
                className="form-control fs-6 fw-semibold"
                name="fecha_inicial"
                id="fecha_inicial"
                value={tera.fecha_inicial}
                onChange={teraCambia}
                required
                />
            </div>
            <div className="col-md-3 ">
              <label className="fs-6 fw-bold p-1">Fecha final</label>
              <input
                type="date"
                className="form-control fs-6 fw-semibold"
                name="fecha_final"
                id="fecha_final"
                value={tera.fecha_final}
                onChange={teraCambia}
                required
                />
            </div>
            <div className="col-md-3 ">
            <label className="fs-6 fw-bold p-1">Acciones</label>
            <div className="d-flex justify-content-between">
              <button className="btn btn-primary btn-sm " onClick={generaReporte}>
                Generar Reporte
              </button>
              <BotonExcelEstilizado reporte={reporte} />
            </div>
            
            </div>
          </div>
        </CardHeader>
        <CardBody className="scrolling">
          <Table bordered hover>
            <thead className="bg-primary text-white">
              <tr>
                <th>Terapeuta</th>
                <th>Paciente</th>
                <th>Terapia</th>
                <th>Fecha</th>
                <th>Inicio</th>
                <th>Fin</th>
                <th>Duración</th>
              </tr>
            </thead>
            <tbody>
              {reporte.map((evento) => {
                return (
                  <tr key={evento.id}>
                    <td>{evento.terapeuta}</td>
                    <td>{evento.paciente}</td>
                    <td>{evento.terapia}</td>
                    <td>{evento.fecha}</td>
                    <td>{dayjs(evento.inicio).format("HH:mm")}</td>
                    <td>{dayjs(evento.fin).format("HH:mm")}</td>
                    <td>{evento.duracion}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Container>
    </div>
  );
};

export default Reportes;