import React, { useReducer } from "react";
import UsuarioReducer from "./UsuarioReducer";
import UsuarioContext from "./UsuarioContext";
import axios from "axios";
import {
  ADD_USUARIO,
  GET_USUARIO,
  GET_EVENTOS,
  SET_EVENTO,
  GET_EXPEDIENTE,
  GET_PACIENTES,
  GET_SESIONES,
  GET_PACIENTE,
  SET_USUARIO,
  SET_RESADD,
  EDIT_USUARIO,
  ADD_DIAGNOSTICO,
  EDIT_DIAGNOSTICO,
  PERFIL_USUARIO,
  APERFIL_USUARIO,
  EPERFIL_USUARIO,
  GET_TERAPIAS,
  GET_APARATOS,
  SET_PACIENTE_SELECCIONADO,
  SET_DIAGNOSTICO_SELECCIONADO,
  SET_SESION,
  GET_TERAPEUTAS,
  GET_TERAPEUTA,
  SET_TERAPIA,
  DISPONIBLES,
  GET_TERAPIA,
  EDIT_EVENTO,
  CANCEL_EVENTO,
  ABONAR_SESION,
  SET_PACIENTE,
  EDIT_PACIENTE,
  LISTA_PACIENTES,
  SET_RESPAGO,
  GET_PAGOS,
  DEL_SESION,BORRAR_TERAPIA,
  REPORTE
} from "../types";

const UsuarioState = (props) => {
  const initialState = {
    resdadd: [],
    resadd: [],
    usuario: [],
    eventos: [],
    expediente: [],
    pacientes: [],
    paciente: [],
    sesiones: [],
    disponibles: [],
    terapia: [],
    editresponse: [],
    cancelarresponse: [],
    abonarresponse: [],
    spres: [],
    resp: "",
    resedit: [],
    resdedit: [],
    perfil: [],
    resaperfil: [],
    reseperfil: [],
    ressetterapia: [],
    ListaTerapias: [],
    ListaAparatos: [],
    PacienteSeleccionado: "",
    DiagnosticoSeleccionado: "",
    resaddsesion: [],
    terapeutas: [],
    terapeuta: [],
    lpres: [],
    epres: [],
    pagos: [],
    delsres:[],
    resbterapia:[],
    cancelresponse:[],
    reporte:[]
  };
  const [state, dispatch] = useReducer(UsuarioReducer, initialState);

  const getReporte = async (datos) => {
    try {
      const res = await axios.post(
        "https://api.clinicacerebro.org/index.php/evento/reporte",
        datos
      );
  
      const data = res.data;
  
      dispatch({
        type: REPORTE,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const CancelarSesion = async (datos) => {
    try {
      const res = await axios.post(
        "https://api.clinicacerebro.org/index.php/evento/cancelar",
        datos
      );
  
      const data = res.data;
  
      dispatch({
        type: CANCEL_EVENTO,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };






const borrarTerapia = async (datos) => {
  try {
    const res = await axios.post(
      "https://api.clinicacerebro.org/index.php/evento/borrarterapia",
      datos
    );

    const data = res.data;

    dispatch({
      type: BORRAR_TERAPIA,
      payload: data,
    });
  } catch (error) {
    console.error(error);
  }
};


  const delSesion = async (datos) => {
    
    try {
      const res = await axios.post(
        "https://api.clinicacerebro.org/index.php/pacientes/delsesion",
        {id:datos}
      );

      const data = res.data;

      dispatch({
        type: DEL_SESION,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };



  const getPagos = async (datos) => {
    
    try {
      const res = await axios.post(
        "https://api.clinicacerebro.org/index.php/pacientes/pagos",
        {sesion:datos}
      );

      const data = res.data;

      dispatch({
        type: GET_PAGOS,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const setPaciente = async (datos) => {
    
    try {
      const res = await axios.post(
        "https://api.clinicacerebro.org/index.php/pacientes/set",
        datos
      );

      const data = res.data;

      dispatch({
        type: SET_PACIENTE,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const editPaciente = async (datos) => {
    
    try {
      const res = await axios.post(
        "https://api.clinicacerebro.org/index.php/pacientes/edit",
        datos
      );

      const data = res.data;

      dispatch({
        type: EDIT_PACIENTE,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const listaPacientes = async (datos) => {
    
    try {
      const res = await axios.get(
        "https://api.clinicacerebro.org/index.php/pacientes/lista"
      );

      const data = res.data;

      dispatch({
        type: LISTA_PACIENTES,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getTerapia = async (datos) => {
    
    try {
      const res = await axios.post(
        "https://api.clinicacerebro.org/index.php/evento/terapia",
        datos
      );

      const data = res.data;

      dispatch({
        type: GET_TERAPIA,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const editEvento = async (datos) => {
    try {
      const res = await axios.post(
        "https://api.clinicacerebro.org/index.php/evento/editar",
        datos
      );

      const data = res.data;

      dispatch({
        type: EDIT_EVENTO,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const cancelarEvento = async (datos) => {
    try {
      const res = await axios.post(
        "https://api.clinicacerebro.org/index.php/evento/cancelar",
        datos
      );

      const data = res.data;

      dispatch({
        type: EDIT_EVENTO,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const getDisponibles = async (datos) => {
    try {
      const res = await axios.post(
        "https://api.clinicacerebro.org/index.php/evento/disponible",
        datos
      );

      const data = res.data;

      dispatch({
        type: DISPONIBLES,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const AgregarSesion = async (datos) => {
    try {
      const res = await axios.post(
        "https://api.clinicacerebro.org/index.php/evento/sesionadd",
        datos
      );

      const data = res.data;

      dispatch({
        type: SET_SESION,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const AbonarSesion = async (datos) => {
    try {
      const res = await axios.post(
        "https://api.clinicacerebro.org/index.php/cols/abonar",
        datos
      );

      const data = res.data;

      dispatch({
        type: ABONAR_SESION,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getTerapeutas = async (usuario, rol, tipo, inicio, fin) => {
    try {
      const res = await axios.get(
        "https://api.clinicacerebro.org/index.php/evento/terapeutas?usuario=" +
          usuario +
          "&rol=" +
          rol +
          "&tipo=" +
          tipo +
          "&inicio=" +
          inicio +
          "&fin=" +
          fin
      );

      const data = res.data;

      dispatch({
        type: GET_TERAPEUTAS,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getTerapeuta = async (usuario, rol, id) => {
    try {
      const res = await axios.get(
        "https://api.clinicacerebro.org/index.php/evento/terapeuta?usuario=" +
          usuario +
          "&rol=" +
          rol +
          "&id=" +
          id
      );

      const data = res.data;

      dispatch({
        type: GET_TERAPEUTA,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getListaAparatos = async () => {
    try {
      const res = await axios.get(
        "https://api.clinicacerebro.org/index.php/cols/listaaparatos"
      );

      const data = res.data;

      dispatch({
        type: GET_APARATOS,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const getListaTerapias = async () => {
    try {
      const res = await axios.get(
        "https://api.clinicacerebro.org/index.php/cols/listaterapias"
      );

      const data = res.data;

      dispatch({
        type: GET_TERAPIAS,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const setTerapia = async (datos) => {
    try {
      const res = await axios.post(
        "https://api.clinicacerebro.org/index.php/cols/addterapia",
        datos
      );

      const data = res.data;

      dispatch({
        type: SET_TERAPIA,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const aPerfil = async (datos) => {
    try {
      const res = await axios.post(
        "https://api.clinicacerebro.org/index.php/usuarios/aperfil",
        datos
      );

      const data = res.data;

      dispatch({
        type: APERFIL_USUARIO,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const ePerfil = async (datos) => {
    try {
      const res = await axios.post(
        "https://api.clinicacerebro.org/index.php/usuarios/eperfil",
        datos
      );

      const data = res.data;

      dispatch({
        type: EPERFIL_USUARIO,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const getPerfil = async (datos) => {
    try {
      const res = await axios.post(
        "https://api.clinicacerebro.org/index.php/usuarios/perfil",
        datos
      );

      const data = res.data;

      dispatch({
        type: PERFIL_USUARIO,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const AgregarDiagnostico = async (datos) => {
    try {
      const res = await axios.post(
        "https://api.clinicacerebro.org/index.php/expediente/Add",
        datos
      );

      const data = res.data;

      dispatch({
        type: ADD_DIAGNOSTICO,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const EditarDiagnostico = async (datos) => {
    
    try {
      const res = await axios.post(
        "https://api.clinicacerebro.org/index.php/expediente/edit",
        datos
      );

      const data = res.data;

      dispatch({
        type: EDIT_DIAGNOSTICO,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const AddUsuario = async (datos) => {
    try {
      const res = await axios.post(
        "https://api.clinicacerebro.org/index.php/usuarios/Add",
        datos
      );

      const data = res.data;

      dispatch({
        type: ADD_USUARIO,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const EditUsuario = async (datos) => {
    try {
      const res = await axios.post(
        "https://api.clinicacerebro.org/index.php/usuarios/edit",
        datos
      );

      const data = res.data;

      dispatch({
        type: EDIT_USUARIO,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const setResadd = () => {
    dispatch({
      type: SET_RESADD,
      payload: [],
    });
  };

  const setResabono = () => {
    dispatch({
      type: SET_RESPAGO,
      payload: [],
    });
  };

  const setPS = (datos) => {
    dispatch({
      type: SET_PACIENTE_SELECCIONADO,
      payload: datos,
    });
  };
  const setDS = (datos) => {
    dispatch({
      type: SET_DIAGNOSTICO_SELECCIONADO,
      payload: datos,
    });
  };

  const setUsuario = (datos) => {
    dispatch({
      type: SET_USUARIO,
      payload: datos,
    });
  };

  const getUsuario = async (datos) => {
    try {
      const res = await axios.post(
        "https://api.clinicacerebro.org/index.php/auth/auth",
        datos
      );

      //  const data = [{ id: "1", nombre: "Ramses Rivero Fragoso", rol: 3 }];

      const data = [res.data];

      dispatch({
        type: GET_USUARIO,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getPacientes = async () => {
    try {
      const res = await axios.get(
        "https://api.clinicacerebro.org/index.php/evento/pacientes"
      );

      const data = res.data;

      dispatch({
        type: GET_PACIENTES,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getPaciente = async (id) => {
    try {
      const res = await axios.get(
        "https://api.clinicacerebro.org/index.php/evento/paciente?id=" + id
      );

      const data = res.data;

      dispatch({
        type: GET_PACIENTE,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getEventos = async (usuario, rol) => {
    try {
      const res = await axios.get(
        "https://api.clinicacerebro.org/index.php/evento/lista?terapeuta=" +
          usuario +
          "&rol=" +
          rol
      );

      const data = res.data;

      dispatch({
        type: GET_EVENTOS,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const setEvento = async (datos) => {
    try {
      const res = await axios.post(
        "https://api.clinicacerebro.org/index.php/evento/add",
        datos
      );

      const data = res.data;

      dispatch({
        type: SET_EVENTO,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getExpediente = async (paciente) => {
    try {
      const res = await axios.get(
        "https://api.clinicacerebro.org/index.php/evento/expediente?paciente=" +
          paciente
      );

      const data = res.data;

      dispatch({
        type: GET_EXPEDIENTE,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getSesiones = async (paciente, diagnostico) => {
    try {
      const res = await axios.get(
        "https://api.clinicacerebro.org/index.php/evento/sesiones?paciente=" +
          paciente +
          "&diagnostico=" +
          diagnostico
      );

      const data = res.data;

      dispatch({
        type: GET_SESIONES,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <UsuarioContext.Provider
      value={{
        usuario: state.usuario,
        eventos: state.eventos,
        respuesta: state.res,
        expediente: state.expediente,
        pacientes: state.pacientes,
        resp: state.resp,
        sesiones: state.sesiones,
        paciente: state.paciente,
        resadd: state.resadd,
        resedit: state.resedit,
        resdadd: state.resdadd,
        resdedit: state.resdedit,
        perfil: state.perfil,
        resaperfil: state.aperfil,
        reseperfil: state.eperfil,
        ListaTerapias: state.ListaTerapias,
        ListaAparatos: state.ListaAparatos,
        PacienteSeleccionado: state.PacienteSeleccionado,
        DiagnosticoSeleccionado: state.DiagnosticoSeleccionado,
        resaddsesion: state.resaddsesion,
        terapeuta: state.terapeuta,
        terapeutas: state.terapeutas,
        ressetterapia: state.ressetterapia,
        disponibles: state.disponibles,
        terapia: state.terapia,
        editresponse: state.editresponse,
        cancelarresponse: state.cancelarresponse,
        abonarresponse: state.abonarresponse,
        lpres: state.lpres,
        epres: state.epres,
        spres: state.spres,
        pagos: state.pagos,
        delsres: state.delsres,
        resbterapia: state.resbterapia,
        cancelresponse: state.cancelresponse,
        reporte: state.reporte,
        CancelarSesion,
        delSesion,
        AbonarSesion,
        editEvento,
        getTerapia,
        AgregarSesion,
        setDS,
        setPS,
        getListaAparatos,
        getListaTerapias,
        setTerapia,
        aPerfil,
        ePerfil,
        setResadd,
        AddUsuario,
        getUsuario,
        getEventos,
        setEvento,
        getExpediente,
        getPacientes,
        getSesiones,
        getPaciente,
        setUsuario,
        EditUsuario,
        AgregarDiagnostico,
        EditarDiagnostico,
        getPerfil,
        getTerapeuta,
        getTerapeutas,
        getDisponibles,
        cancelarEvento,
        setPaciente,
        editPaciente,
        listaPacientes,
        setResabono,
        getPagos,
        borrarTerapia,getReporte
      }}
    >
      {props.children}
    </UsuarioContext.Provider>
  );
};

export default UsuarioState;
