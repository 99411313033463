import {
  GET_USUARIO,
  GET_EVENTOS,
  SET_EVENTO,
  GET_EXPEDIENTE,
  GET_PACIENTES,
  GET_SESIONES,
  GET_PACIENTE,
  SET_USUARIO,
  ADD_USUARIO,
  SET_RESADD,
  EDIT_USUARIO,
  ADD_DIAGNOSTICO,
  EDIT_DIAGNOSTICO,
  PERFIL_USUARIO,
  APERFIL_USUARIO,
  EPERFIL_USUARIO,
  GET_TERAPIAS,
  GET_APARATOS,
  SET_PACIENTE_SELECCIONADO,
  SET_DIAGNOSTICO_SELECCIONADO,
  SET_SESION,
  GET_TERAPEUTAS,
  GET_TERAPEUTA,
  SET_TERAPIA,
  DISPONIBLES,
  GET_TERAPIA,
  EDIT_EVENTO,CANCEL_EVENTO,
  ABONAR_SESION,
  SET_PACIENTE,
  EDIT_PACIENTE,
  LISTA_PACIENTES,
  SET_RESPAGO,
  GET_PAGOS,
  DEL_SESION,
  BORRAR_TERAPIA,
  REPORTE
} from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case REPORTE:
      return {
        ...state,
        reporte: payload,
      };
    case BORRAR_TERAPIA:
      return {
        ...state,
        resbterapia: payload,
      };
    case EDIT_PACIENTE:
      return {
        ...state,
        epres: payload,
      }; 
      case DEL_SESION:
        return {
          ...state,
          delsres: payload,
        }; 
    case GET_PAGOS:
          return {
            ...state,
            pagos: payload,
          }; 

    case LISTA_PACIENTES:
            return {
              ...state,
              lpres: payload,
            };

    case SET_PACIENTE:
      return {
        ...state,
        spres: payload,
      }; 
    case CANCEL_EVENTO:
      return {
        ...state,
        cancelresponse: payload,
      }; 
      case ABONAR_SESION:
        return {
          ...state,
          abonarresponse: payload,
        }; 
    case EDIT_EVENTO:
      return {
        ...state,
        editresponse: payload,
      }; 
    case GET_TERAPIA:
      return {
        ...state,
        terapia: payload,
      }; 
    case DISPONIBLES:
      return {
        ...state,
        disponibles: payload,
      };  
    case GET_TERAPEUTAS:
      return {
        ...state,
        terapeutas: payload,
      };
      case SET_TERAPIA:
        return {
          ...state,
          ressetterapia: payload,
        };
    case GET_TERAPEUTA:
      return {
        ...state,
        terapeuta: payload,
      };

    case SET_SESION:
      return {
        ...state,
        resaddsesion: payload,
      };
    case SET_PACIENTE_SELECCIONADO:
      return {
        ...state,
        PacienteSeleccionado: payload,
      };
    case SET_DIAGNOSTICO_SELECCIONADO:
      return {
        ...state,
        DiagnosticoSeleccionado: payload,
      };
    case GET_APARATOS:
      return {
        ...state,
        ListaAparatos: payload,
      };
    case GET_TERAPIAS:
      return {
        ...state,
        ListaTerapias: payload,
      };
    case APERFIL_USUARIO:
      return {
        ...state,
        resaperfil: payload,
      };
    case EPERFIL_USUARIO:
      return {
        ...state,
        reseperfil: payload,
      };

    case PERFIL_USUARIO:
      return {
        ...state,
        perfil: payload,
      };

    case ADD_DIAGNOSTICO:
      return {
        ...state,
        resdadd: payload,
      };

    case EDIT_DIAGNOSTICO:
      return {
        ...state,
        resdedit: payload,
      };

    case SET_RESADD:
      return {
        ...state,
        resadd: [],
      };

      case SET_RESPAGO:
        return {
          ...state,
          abonarresponse: [],
        };

    case ADD_USUARIO:
      return {
        ...state,
        resadd: payload,
      };

    case EDIT_USUARIO:
      return {
        ...state,
        resedit: payload,
      };
    case SET_USUARIO:
      return {
        ...state,
        usuario: payload,
      };
      
    case GET_USUARIO:
      return {
        ...state,
        usuario: payload,
      };

    case GET_EVENTOS:
      return {
        ...state,
        eventos: payload,
      };
    case GET_EXPEDIENTE:
      return {
        ...state,
        expediente: payload,
      };
    case GET_SESIONES:
      return {
        ...state,
        sesiones: payload,
      };
    case GET_PACIENTES:
      return {
        ...state,
        pacientes: payload,
      };
    case GET_PACIENTE:
      return {
        ...state,
        paciente: payload,
      };
    case SET_EVENTO:
      return {
        ...state,
        resp: payload,
      };
    default:
      return {
        state,
      };
  }
};
