import React, { useContext } from "react";
import NuevoUsuario from "../componentes/nuevousuario";
import UsuarioContext from "../context/usuarios/UsuarioContext";

export default function Altas() {
const {setPS,setDS} = useContext(UsuarioContext)

React.useEffect(() => {
  setPS(0)
  setDS(0)
},[])
  return (
   <div className=" pt-4 mt-5 ">
      <NuevoUsuario />
   </div>
  );
}
